import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function useMedicamentos() {
    const medicamento = ref(null);
    const medicamentos = ref([]);

    const getMedicamento = async (id) => {
        // medicamento.value = medicamentos.value.find((d) => id === d.id);
        const res = await axios.get(`/care/medicamentos/${id}`);

        if (res.status === 200) {
            medicamento.value = res.data;
        }
    };

    const getMedicamentos = async (searchQuery) => {
        // console.log('Mock');
        // medicamentos.value = [
        //     { selectName: 'MEDICAMENTO 1', id: 1, indicaciones: 'Indicaciones del medicamento 1' },
        //     { selectName: 'MEDICAMENTO 2', id: 2, indicaciones: 'Indicaciones del medicamento 2' },
        //     { selectName: 'MEDICAMENTO 3', id: 3, indicaciones: 'Indicaciones del medicamento 3' },
        //     { selectName: 'MEDICAMENTO 4', id: 4, indicaciones: 'Indicaciones del medicamento 4' },
        //     { selectName: 'MEDICAMENTO 5', id: 5, indicaciones: 'Indicaciones del medicamento 5' },
        //     { selectName: 'MEDICAMENTO 6', id: 6, indicaciones: 'Indicaciones del medicamento 6' },
        //     { selectName: 'MEDICAMENTO 7', id: 7, indicaciones: 'Indicaciones del medicamento 7' },
        //     { selectName: 'MEDICAMENTO 8', id: 8, indicaciones: 'Indicaciones del medicamento 8' },
        //     { selectName: 'MEDICAMENTO 9', id: 9, indicaciones: 'Indicaciones del medicamento 9' },
        //     { selectName: 'MEDICAMENTO 10', id: 10, indicaciones: 'Indicaciones del medicamento 10' },
        //     { selectName: 'MEDICAMENTO 11', id: 11, indicaciones: 'Indicaciones del medicamento 11' },
        //     { selectName: 'MEDICAMENTO 12', id: 12, indicaciones: 'Indicaciones del medicamento 12' },
        // ];
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/medicamentos${query}`);

        if (res.status === 200) {
            medicamentos.value = res.data.items;
        }
    };

    const createMedicamento = async (data) => {
        const res = await axios.post('/care/medicamentos', data);
        if (res.status === 201) {
            // console.log(res.data);
            medicamento.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateMedicamento = async (id, data) => {
        const res = await axios.put(`/care/medicamentos/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            medicamento.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const removeMedicamento = async (id) => {
        const res = await axios.delete(`/care/medicamentos/${id}`);
        if (res.status === 200) {
            // console.log(res.data);
            medicamento.value = undefined;
            if (medicamentos.value) {
                medicamentos.value = medicamentos.value.filter((e) => e.id !== id);
            }
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        medicamento,
        medicamentos,
        getMedicamento,
        getMedicamentos,
        createMedicamento,
        updateMedicamento,
        removeMedicamento,
    };
}
